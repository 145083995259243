import classNames from 'classnames'
import {MARK_BOLD, render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {TCardNumbers} from '@/common/types'

import styles from './CardNumbers.module.scss'

const LeftArrow = () => (
  <svg
    viewBox='0 0 36 80'
    width='36'
    height='80'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    className={styles.arrowSVG}
  >
    <path d='M36 0 L0 40 L36 80' className={styles.arrowPath} />
  </svg>
)

const RightArrow = () => (
  <svg
    viewBox='0 0 36 80'
    width='36'
    height='80'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    className={classNames(styles.arrowSVG, styles.isRight)}
  >
    <path d='M0 0 L36 40 L0 80' className={styles.arrowPath} />
  </svg>
)

export const Illustration = (): JSX.Element => {
  return (
    <div className={styles.illustration}>
      <LeftArrow />
      <LeftArrow />
      <RightArrow />
      <RightArrow />
    </div>
  )
}

const CardNumbers = ({heading, content, cards = []}: TCardNumbers): JSX.Element => {
  return (
    <section className={styles.section}>
      {(heading || content) && (
        <div className={styles.container}>
          {heading && <div className={styles.heading}>{heading}</div>}
          {content && <div className={styles.content}>{content}</div>}
        </div>
      )}

      <div className={styles.cardsOutterContainer}>
        <Illustration />
        <div className={styles.container}>
          <div className={styles.cards}>
            {cards.length > 0 &&
              cards.map(({_uid, component, numbers, content}) => {
                if (component !== componentTypes.CARD_NUMBERS_ITEM) return null
                return (
                  <div className={styles.card} key={_uid}>
                    {numbers && <div className={styles.cardNumbers}>{numbers}</div>}
                    {content && (
                      <div className={styles.cardContent}>
                        {render(content, {
                          markResolvers: {
                            // eslint-disable-next-line react/display-name
                            [MARK_BOLD]: (children: string) => (
                              <b className={styles.bold}>{children}</b>
                            ),
                          },
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardNumbers
